body {
  margin: 0;
  font-size: 16px;
  line-height: 1.56;
  color: #333;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h4 {
  font-size: 18px;
  line-height: 1.55;
  font-weight: 500;
  margin-bottom: 2px;
}

h1 {
  margin-bottom: 2px;
  font-weight: 500;
}

p {
  margin: 0px;
}

html {
  font-family: sans-serif;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
